import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./App.css";
import MasterLayout from "Layout/MasterLayout";
import MainRouter from "routes/mainRouter";
import ScrollTop from "Layout/scrollTop";

function App() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("ko");
  useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);
  return (
    <>
      <MasterLayout>
        <MainRouter />
      </MasterLayout>
      <ScrollTop />
    </>
  );
}

export default App;
