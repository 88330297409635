import { useTranslation } from "react-i18next";
import "design/communityCss/recurit.css";
import SearchIcon from "assets/images/search.jpg";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import _ from "lodash";
const Recruit = () => {
  const { t } = useTranslation();
  const [searchOption, setSearchOption] = useState("title");
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState<any>([]);
  const getSearchedList = (search: string) => {
    if (search === "") return list;
    if (searchOption === "title")
      setSearchList(
        _.filter(list, (item: any) => {
          return item.position?.title.includes(search);
        }),
      );
  };
  const getList = useCallback(() => {
    // axios.get("http://localhost:8080/api").then((res) => {
    axios.get("https://young-it.co.kr:8081/api").then((res) => {
      setList(res.data?.jobs?.job);
      // setList([]);
    });
  }, []);
  useEffect(() => {
    getList();
  }, []);
  return (
    <div className={"recruit-container"}>
      <div className={"recruit-title"}>
        <h1>{t("menu.recruit")}</h1>
      </div>
      <div className={"recruit-content"}>
        <div className={"search-section"}>
          <select defaultValue={searchOption}>
            <option value="title">{t("community.recruit.search.title")}</option>
            {/*<option value="content">*/}
            {/*  {t("community.recruit.search.content")}*/}
            {/*</option>*/}
          </select>
          <input
            type={"text"}
            placeholder={t("community.recruit.search.placeholder")}
            className={"search-input"}
            value={search}
            onChange={(e) => {
              if (e.target.value === "") {
                setSearch("");
                setSearchList([]);
              }
              setSearch(e.target.value);
            }}
          />
          <button onClick={() => getSearchedList(search)}>
            <img src={SearchIcon} alt={"search"} width={15} height={15} />
          </button>
        </div>
        <div className={"table-section"}>
          <span>{t("community.recruit.table.click")}</span>
          <table>
            <thead>
              <tr>
                <th>{t("community.recruit.table.category")}</th>
                <th>{t("community.recruit.table.title")}</th>
                <th>{t("community.recruit.table.career")}</th>
                <th>{t("community.recruit.table.required")}</th>
                <th>{t("community.recruit.table.endAt")}</th>
              </tr>
            </thead>
            {list?.length > 0 ? (
              searchList?.length > 0 ? (
                <tbody>
                  {searchList.map((item: any, index: number) => {
                    const timestamp = Number(item["expiration-timestamp"]);
                    const dateInMilliseconds = timestamp * 1000;
                    const date = new Date(dateInMilliseconds);
                    const koreanTimeOffset = 9 * 60; // 한국은 UTC+9 시간대입니다.
                    const utcDate =
                      date.getTime() + date.getTimezoneOffset() * 60000;
                    const koreaDate = new Date(
                      utcDate + koreanTimeOffset * 60000,
                    );
                    const year = koreaDate.getFullYear();
                    const month = ("0" + (koreaDate.getMonth() + 1)).slice(-2);
                    const day = ("0" + koreaDate.getDate()).slice(-2);
                    const formattedDate = `${year}.${month}.${day}`;
                    return (
                      <tr key={index} onClick={() => window.open(item?.url)}>
                        <td>{item.position["job-mid-code"].name}</td>
                        <td>{item.position?.title}</td>
                        <td>{item.position["experience-level"].name}</td>
                        <td>
                          {item.position["required-education-level"].name}
                        </td>
                        <td>{formattedDate}</td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  {list.map((item: any, index: number) => {
                    const timestamp = Number(item["expiration-timestamp"]);
                    const dateInMilliseconds = timestamp * 1000;

                    const date = new Date(dateInMilliseconds);

                    const koreanTimeOffset = 9 * 60; // 한국은 UTC+9 시간대입니다.
                    const utcDate =
                      date.getTime() + date.getTimezoneOffset() * 60000;
                    const koreaDate = new Date(
                      utcDate + koreanTimeOffset * 60000,
                    );

                    const year = koreaDate.getFullYear();
                    const month = ("0" + (koreaDate.getMonth() + 1)).slice(-2);
                    const day = ("0" + koreaDate.getDate()).slice(-2);

                    const formattedDate = `${year}.${month}.${day}`;

                    return (
                      <tr key={index} onClick={() => window.open(item?.url)}>
                        <td>{item.position["job-mid-code"].name}</td>
                        <td>{item.position?.title}</td>
                        <td>{item.position["experience-level"].name}</td>
                        <td>
                          {item.position["required-education-level"].name}
                        </td>
                        <td>{formattedDate}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )
            ) : (
              <tbody>
                <tr>
                  <td colSpan={5}>{t("community.recruit.table.noData")}</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
export default Recruit;
