import TopIcon from "assets/images/top.png";
import "design/LayoutCss/scrollTop.css";
import * as url from "url";
import { useEffect, useState } from "react";
const ScrollTop = () => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <div
      className={"scroll-top-section"}
      style={{
        display: visible ? "block" : "none",
      }}
      onClick={scrollToTop}
    ></div>
  );
};

export default ScrollTop;
