import "design/errCss/errScreen.css";
import { errScreen } from "assets/assetsDeclear";
const ErrorScreen = () => {
  return (
    <div className={"err-screen"}>
      <div className={"err-container"}>
        <img src={errScreen} alt="err" />
      </div>
    </div>
  );
};
export default ErrorScreen;
