import { useTranslation } from "react-i18next";
import "design/communityCss/guide.css";
const Guide = () => {
  const { t } = useTranslation();

  return (
    <div className={"guide-container"}>
      <div className={"guide-title"}>
        <h1>{t("menu.guide")}</h1>
      </div>
      <div className={"guide-content"}>
        <div className={"__guide_section"}>
          <div className={"guide-card"} />
          <div className={"guide-card"} />
          <div className={"guide-card"} />
        </div>
      </div>
    </div>
  );
};
export default Guide;
