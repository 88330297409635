import { persistStore } from "redux-persist";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./connectors.redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);
export const persistor = persistStore(store);
