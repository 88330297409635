export const changeLanguage = async (action: string, payload: any) => {
  switch (action) {
    case "change":
      return {
        type: "CHANGE_LANGUAGE",
        payload: payload,
      };

    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};
