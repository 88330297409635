import "design/mainCss/newsRoom.css";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useEffect } from "react";
const NewsRoom = () => {
  const { t } = useTranslation();
  const prevButton: any = document.getElementById("news-prev");
  const nextButton: any = document.getElementById("news-next");
  const cardContainer: any = document.querySelector(".content-card-container");

  if (prevButton && cardContainer) {
    prevButton.addEventListener("click", function () {
      cardContainer.scrollLeft -= 150;
    });
  }

  if (nextButton && cardContainer) {
    nextButton.addEventListener("click", function () {
      cardContainer.scrollLeft += 150;
    });
    cardContainer.addEventListener("scroll", () => {
      // 스크롤 위치에 따라 버튼의 disabled 상태를 업데이트합니다.
      prevButton.disabled = cardContainer.scrollLeft === 0;
    });

    // 초기 페이지 로드 시 버튼의 disabled 상태를 설정합니다.
    window.onload = () => {
      prevButton.disabled = cardContainer.scrollLeft === 0;
    };
  }

  return (
    <div className={"news-container"}>
      <div className={"title"}>
        <h1>{t("news.title")}</h1>
      </div>
      <div className={"news-content"}>
        <div className={"content-title"}>
          <h1>{t("news.subTitle")}</h1>
        </div>
        {/*<button id="news-prev" disabled={cardContainer?.scrollLeft === 0}>*/}
        {/*  <KeyboardDoubleArrowLeftIcon className={"btn-icon"} />*/}
        {/*</button>*/}
        <div className={"content-card-container"}>
          <div className={"content-card"}>
            <div className={"content-card-header"}>
              <img
                src={"https://cdn.gasnews.com/image/logo/favicon.png"}
                alt={"gasnews"}
                className={"content-card-header-img"}
              />
              <div className={"header-section"}>
                <h5>{t("news.item1.title")}</h5>
                <span>{t("news.item1.date")}</span>
              </div>
            </div>
            <div className={"content-card-main "} id="news-main">
              <img
                src={
                  "https://cdn.gasnews.com/news/photo/202305/110307_81096_155.png"
                }
                alt={"gas-device"}
                className={"content-card-main-img"}
              />
              <span>{t("news.item1.content")}</span>
            </div>
          </div>
        </div>

        {/*<button id="news-next" disabled={cardContainer?.scrollWidth < 1300}>*/}
        {/*  <KeyboardDoubleArrowRightIcon className={"btn-icon"} />*/}
        {/*</button>*/}
      </div>
    </div>
  );
};
export default NewsRoom;
