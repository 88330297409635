import { combineReducers } from "redux";
import { penderReducer } from "redux-pender";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage";
import languageReducer from "./reducers/LanguageReducer";
const persistConfig = {
  key: "youngit",
  storage: sessionStorage,
  whitelist: ["language"],
};

const rootReducer = combineReducers({
  language: languageReducer,
  pender: penderReducer,
});

// export default rootReducer

export default persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
