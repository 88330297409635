import Header from "Layout/components/Header";
import Footer from "Layout/components/Footer";

const MasterLayout = ({ children }: any) => {
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};
export default MasterLayout;
