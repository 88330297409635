import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "locales/i18n";
import { useSelector } from "react-redux";
import "design/introduceCss/overview.css";
import { overview } from "assets/assetsDeclear";
const Overview = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  setTimeout(() => {
    const video = document.querySelector("video");
    video?.play();
  }, 1000);
  return (
    <>
      <div className={"overview-title"}>
        <h1>{t("menu.overview")}</h1>
      </div>
      <div className={"overview-content-text"}>
        <h1>{t("overview.about")}</h1>
        <span>{t("overview.detail")}</span>
      </div>
      <div className={"overview-container"}>
        <div className={"overview-content"}>
          <ul>
            <li>
              <div>
                <div>
                  <h3>{t("overview.companyName")}</h3>

                  <span>{t("overview.youngit")}</span>
                </div>
              </div>
              <div>
                <div>
                  <h3>{t("overview.ceo")}</h3>
                  <span>{t("overview.ceoName")}</span>
                </div>
              </div>
              <div>
                <div>
                  <h3>{t("overview.location")}</h3>
                  <span>{t("overview.address")}</span>
                </div>
              </div>
              <div>
                <div>
                  <h3>{t("overview.contact")}</h3>
                  <div className={"contact-box"}>
                    <span>T.042-932-0170</span>
                    <span>E.young-it@young-it.co.kr</span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <video controls muted loop>
                <source src={overview} type="video/mp4" />
              </video>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Overview;
