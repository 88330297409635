import { Route, Routes } from "react-router-dom";
import HeaderBackground from "screens/community/layout/headerBackground";
import SmartCheck from "screens/business/componets/smartCheck";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import i18n from "locales/i18n";
import Guide from "screens/community/components/guide";
import ErrorScreen from "screens/error/errorScreen";
import Notice from "screens/community/components/notice";
import Recruit from "screens/community/components/recruit";

const CommunityScreen = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  return (
    <Routes>
      <Route
        path="/guide"
        element={
          <div>
            <HeaderBackground
              route={[t("header.community"), t("menu.guide")]}
            />
            <Guide />
          </div>
        }
      />
      <Route
        path="/notice"
        element={
          <div>
            <HeaderBackground
              route={[t("header.community"), t("menu.notice")]}
            />
            <Notice />
          </div>
        }
      />
      <Route
        path="/recruit"
        element={
          <div>
            <HeaderBackground
              route={[t("header.community"), t("menu.recruit")]}
            />
            <Recruit />
          </div>
        }
      />
      <Route path={"*"} element={<ErrorScreen />} />
    </Routes>
  );
};
export default CommunityScreen;
