import BannerSection from "screens/main/components/Banner";
import LineBanner from "screens/main/components/lineBanner";
import Product from "screens/main/components/product";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import i18n from "locales/i18n";
import NewsRoom from "screens/main/components/NewsRoom";

const MainScreen = () => {
  const language = useSelector((state: any) => state?.language?.language);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  return (
    <div className={""}>
      <BannerSection />
      <LineBanner />
      <Product />
      <NewsRoom />
    </div>
  );
};
export default MainScreen;
