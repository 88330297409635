import _ from "lodash";
import "./customBreadCrumbs.css";
import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const CustomBreadCrumbs = (props: any) => {
  const { routes } = props;
  const breadcrumbs = _.map(routes, (route: any, index: number) => {
    const isLast = index === routes.length - 1; // 현재 요소가 마지막 요소인지 확인
    return (
      <React.Fragment key={index}>
        <div style={{ textTransform: "uppercase" }}>
          <span>{route}</span>
        </div>
        {!isLast && <NavigateNextIcon />}
      </React.Fragment>
    );
  });
  return (
    <div className={"d-flex gap-2 align-items-center crumbs-fs "}>
      {breadcrumbs}
    </div>
  );
};

export default CustomBreadCrumbs;
