import "design/introduceCss/organization.css";
import { useTranslation } from "react-i18next";
const Organization = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={"organization_container"}>
        <div className={"sub_content"}>
          <div className={"sub_organization"}>
            {/*<h3 className={"sub_title"}>조직도</h3>*/}
            <div className={"sub_organization_con"}>
              <h4>{t("about.organization.ceo")}</h4>
              <div className={"sub_organi_box"}>
                <dl>
                  <dt>{t("about.organization.advisor")}</dt>
                </dl>
                <dl>
                  <dt>{t("about.organization.main1.title")}</dt>
                  <dd>{t("about.organization.main1.sub1")}</dd>
                  <dd>{t("about.organization.main1.sub2")}</dd>
                  <dd>{t("about.organization.main1.sub3")}</dd>
                  <dd>{t("about.organization.main1.sub4")}</dd>
                </dl>
                <dl>
                  <dt>{t("about.organization.main2.title")}</dt>
                  <dd>{t("about.organization.main2.sub1")}</dd>
                  <dd>{t("about.organization.main2.sub2")}</dd>
                  <dd>{t("about.organization.main2.sub3")}</dd>
                </dl>

                <dl>
                  <dt>{t("about.organization.main3.title")}</dt>
                  <dd>{t("about.organization.main3.sub1")}</dd>
                  <dd>{t("about.organization.main3.sub2")}</dd>
                  <dd>{t("about.organization.main3.sub3")}</dd>
                </dl>
                <dl>
                  <dt>{t("about.organization.main4.title")}</dt>
                  <dd>{t("about.organization.main4.sub1")}</dd>
                  <dd>{t("about.organization.main4.sub2")}</dd>
                  <dd>{t("about.organization.main4.sub3")}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Organization;
