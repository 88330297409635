export const Logo = require("assets/images/yit.jpg");
export const LogoWhite = require("assets/images/yit-white.png");

export const iot = require("assets/images/iot.jpg");

export const network = require("assets/images/network.jpg");
export const remote = require("assets/images/remote.jpg");
export const cloud = require("assets/images/cloud.jpg");
export const device = require("assets/images/device.jpg");
export const smartCheck = require("assets/images/business-smartcheck.jpg");

export const overview = require("assets/video/overview-yit.mp4");
export const errScreen = require("assets/images/404.jpg");
