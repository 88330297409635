import "design/aboutCss/aboutHeader.css";
import CustomBreadCrumbs from "design/common/customBreadCrumbs";
import { useTranslation } from "react-i18next";
const HeaderBackground = (props: any) => {
  const { route } = props;
  const { t } = useTranslation();
  return (
    <div className={"about-container"}>
      <div className={"about-header-image"}>
        <div className={"about-header-image-navi"}>
          <CustomBreadCrumbs routes={route} />
        </div>
      </div>
    </div>
  );
};
export default HeaderBackground;
