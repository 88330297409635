import "design/mainCss/banner.css";
import { cloud, iot, network, remote } from "assets/assetsDeclear";
import { useTranslation } from "react-i18next";
import Slide from "react-slick";
const BannerSection = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    autoplay: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
  };
  return (
    <div className="banner-section">
      <Slide {...settings}>
        <div className="banner-img">
          <div
            className={"splide-banner"}
            style={{
              backgroundImage: `url(${remote})`,
            }}
          />
          <div className="banner-text">
            <div className="banner__content">
              <strong>
                <em>{t("main.banner.smartDigitalImage")}</em>
                <br />
                {t("main.banner.remoteSystem")}
              </strong>
              <p>{t("main.banner.banner1des")}</p>
            </div>
          </div>
        </div>
        <div className="banner-img">
          <div
            className={"splide-banner"}
            style={{
              backgroundImage: `url(${cloud})`,
            }}
          />
          <div className="banner-text">
            <div className="banner__content">
              <strong>
                <em>{t("main.banner2.title")}</em>
                <br />
                {t("main.banner2.subTitle")}
              </strong>
              <p>{t("main.banner2.description")}</p>
            </div>
          </div>
        </div>
        <div className="banner-img">
          <div
            className={"splide-banner"}
            style={{
              backgroundImage: `url(${iot})`,
            }}
          />
          <div className="banner-text">
            <div className="banner__content">
              <strong>
                <em>{t("main.banner3.title")}</em>
                <br />
                {t("main.banner3.subTitle")}
              </strong>
              <p>{t("main.banner3.description")}</p>
            </div>
          </div>
        </div>
        <div className="banner-img">
          <div
            className={"splide-banner"}
            style={{
              backgroundImage: `url(${network})`,
            }}
          />
          <div className="banner-text">
            <div className="banner__content">
              <strong>
                <em>{t("main.banner4.title")}</em>
                <br />
                {t("main.banner4.subTitle")}
              </strong>
              <p>{t("main.banner4.description")}</p>
            </div>
          </div>
        </div>
      </Slide>
      {/*<Splide*/}
      {/*  options={{*/}
      {/*    rewind: true,*/}
      {/*    pagination: false,*/}
      {/*    autoplay: true,*/}
      {/*    infinite: true,*/}
      {/*    drag: false,*/}
      {/*    speed: 1500,*/}
      {/*    interval: 5000,*/}
      {/*    arrows: false,*/}
      {/*    pauseOnHover: false,*/}
      {/*    easing: "ease",*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <SplideSlide>*/}
      {/*    <div className="banner-img">*/}
      {/*      <div*/}
      {/*        className={"splide-banner"}*/}
      {/*        style={{*/}
      {/*          backgroundImage: `url(${remote})`,*/}
      {/*        }}*/}
      {/*      />*/}
      {/*      <div className="banner-text">*/}
      {/*        <div className="banner__content">*/}
      {/*          <strong>*/}
      {/*            <em>{t("main.banner.smartDigitalImage")}</em>*/}
      {/*            <br />*/}
      {/*            {t("main.banner.remoteSystem")}*/}
      {/*          </strong>*/}
      {/*          <p>{t("main.banner.banner1des")}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </SplideSlide>*/}
      {/*  <SplideSlide>*/}
      {/*    <div className="banner-img">*/}
      {/*      <div*/}
      {/*        className={"splide-banner"}*/}
      {/*        style={{*/}
      {/*          backgroundImage: `url(${cloud})`,*/}
      {/*        }}*/}
      {/*      />*/}
      {/*      <div className="banner-text">*/}
      {/*        <div className="banner__content">*/}
      {/*          <strong>*/}
      {/*            <em>{t("main.banner2.title")}</em>*/}
      {/*            <br />*/}
      {/*            {t("main.banner2.subTitle")}*/}
      {/*          </strong>*/}
      {/*          <p>{t("main.banner2.description")}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </SplideSlide>*/}
      {/*  <SplideSlide>*/}
      {/*    <div className="banner-img">*/}
      {/*      <div*/}
      {/*        className={"splide-banner"}*/}
      {/*        style={{*/}
      {/*          backgroundImage: `url(${iot})`,*/}
      {/*        }}*/}
      {/*      />*/}
      {/*      <div className="banner-text">*/}
      {/*        <div className="banner__content">*/}
      {/*          <strong>*/}
      {/*            <em>{t("main.banner3.title")}</em>*/}
      {/*            <br />*/}
      {/*            {t("main.banner3.subTitle")}*/}
      {/*          </strong>*/}
      {/*          <p>{t("main.banner3.description")}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </SplideSlide>*/}
      {/*  <SplideSlide>*/}
      {/*    <div className="banner-img">*/}
      {/*      <div*/}
      {/*        className={"splide-banner"}*/}
      {/*        style={{*/}
      {/*          backgroundImage: `url(${network})`,*/}
      {/*        }}*/}
      {/*      />*/}
      {/*      <div className="banner-text">*/}
      {/*        <div className="banner__content">*/}
      {/*          <strong>*/}
      {/*            <em>{t("main.banner4.title")}</em>*/}
      {/*            <br />*/}
      {/*            {t("main.banner4.subTitle")}*/}
      {/*          </strong>*/}
      {/*          <p>{t("main.banner4.description")}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </SplideSlide>*/}
      {/*</Splide>*/}
    </div>
  );
};
export default BannerSection;
