import { Route, Routes } from "react-router-dom";
import Overview from "screens/introduce/components/overview";
import HeaderBackground from "screens/product/layout/headerBackground";
import { useTranslation } from "react-i18next";
import Organization from "screens/introduce/components/organization";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import i18n from "locales/i18n";
import Software from "screens/product/components/software";
import Hardware from "screens/product/components/hardware";
import ErrorScreen from "screens/error/errorScreen";

const ProductScreen = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  return (
    <Routes>
      <Route
        path="/software"
        element={
          <div>
            <HeaderBackground
              route={[t("header.product"), t("menu.software")]}
            />
            <Software />
          </div>
        }
      />
      <Route
        path="/hardware"
        element={
          <div>
            <HeaderBackground
              route={[t("header.product"), t("menu.hardWare")]}
            />
            <Hardware />
          </div>
        }
      />
      <Route path={"*"} element={<ErrorScreen />} />
    </Routes>
  );
};

export default ProductScreen;
