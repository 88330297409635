import "design/businessCss/smartCheck.css";
import { useTranslation } from "react-i18next";
import { overview } from "assets/assetsDeclear";
const SmartCheck = () => {
  const { t } = useTranslation();
  return (
    <div className={"smartcheck-container"}>
      <div className={"smartcheck-title"}>
        <h1>{t("menu.smartCheck")}</h1>
      </div>
      <div className={"smartcheck-content"}>
        <div className={"smartcheck-img"}>
          {/*<h1>{t("business.smartCheck.title.main")}</h1>*/}
        </div>
        <div className={"smartcheck-list"}>
          <div className={"smartcheck-list-title"}>
            <h1>{t("business.smartCheck.title.server")}</h1>
          </div>
          <div className={"smartcheck-list-content"}>
            <p>{t("business.smartCheck.server")}</p>
          </div>
        </div>
        <div className={"smartcheck-list"}>
          <div className={"smartcheck-list-title"}>
            <h1>{t("business.smartCheck.title.data")}</h1>
          </div>
          <div className={"smartcheck-list-content"}>
            <p>{t("business.smartCheck.data")}</p>
          </div>
        </div>
        <div className={"smartcheck-list"}>
          <div className={"smartcheck-list-title"}>
            <h1>{t("business.smartCheck.title.service")}</h1>
          </div>
          <div className={"smartcheck-list-content"}>
            <p>{t("business.smartCheck.service")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartCheck;
