import "design/mainCss/product.css";
import { useTranslation } from "react-i18next";
const Product = () => {
  const { t } = useTranslation();
  return (
    <div className={"product-container"}>
      <div className={"title"}>
        <h1>{t("product.title")}</h1>
      </div>
      <div className={"product-content"}>
        <div className={"product-item"}>
          <div className={"product-item-left"}>
            <div className="product-item-text">
              <h1>{t("product.item1.title")}</h1>
            </div>
            <div className={"product-item-left-cover"} />
            <div className={"product-item-left-detail "}>
              <div className={"content  "}>
                <h1>{t("product.item1.title")}</h1>
                <a href={"/product/software"}>{t("product.button")}</a>
              </div>
            </div>
          </div>

          <div className={"product-item-right"}>
            <div className="product-image-container product-image-1">
              <div className="product-image-text">
                <h1>{t("product.item2.title")}</h1>
              </div>
              <div className={"product-image-1-detail "}>
                <div className={"content "}>
                  <h1>{t("product.item2.title")}</h1>
                  <a href={"/product/hardware"}>{t("product.button")}</a>
                </div>
              </div>
            </div>
            <div className="product-image-container product-image-2">
              <div className="product-image-text">
                <h1>{t("product.item3.title")}</h1>
              </div>
              <div className={"product-image-2-detail "}>
                <div className={"content "}>
                  <h1>{t("product.item3.title")}</h1>
                  <a href={"/product/software"}>{t("product.button")}</a>
                </div>
              </div>
            </div>
            <div className="product-image-container product-image-3">
              <div className="product-image-text">
                <h1>{t("product.item4.title")}</h1>
              </div>
              <div className={"product-image-3-detail "}>
                <div className={"content "}>
                  <h1>{t("product.item4.title")}</h1>
                  <a href={"/product/software"}>{t("product.button")}</a>
                </div>
              </div>
            </div>
            <div className="product-image-container product-image-4">
              <div className="product-image-text">
                <h1>{t("product.item5.title")}</h1>
              </div>
              <div className={"product-image-4-detail "}>
                <div className={"content "}>
                  <h1>{t("product.item5.title")}</h1>
                  <a href={"/business/smart-check"}>{t("product.button")}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Product;
