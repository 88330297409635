import "design/referenceCss/brochure.css";
import { useTranslation } from "react-i18next";
import BrochurePDF from "assets/pdf/brochure.pdf";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const Brochure = () => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <div className={"brochure-container"}>
      <div className={"brochure-title"}>
        <h1>{t("menu.brochure")}</h1>
      </div>
      <div className={"brochure-content"}>
        <Document
          file={BrochurePDF}
          onLoadSuccess={onDocumentLoadSuccess}
          className={"align-self-center"}
          loading={
            <div>
              <div className="spinner-border" role="status">
                <span className="visually-hidden">
                  {t("reference.brochure.loading")}
                </span>
              </div>
            </div>
          }
        >
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            _className={"pdf-page"}
            loading={
              <div>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">
                    {t("reference.brochure.loading")}
                  </span>
                </div>
              </div>
            }
          />
        </Document>
        <div className={"pdf-btn"}>
          <button
            onClick={() => setPageNumber(pageNumber - 1)}
            disabled={pageNumber <= 1}
          >
            {t("reference.brochure.prevBtn")}
          </button>
          <p className={"text-center"}>
            {t("reference.brochure.page")} {pageNumber} of {numPages}
          </p>
          <button
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber >= 14}
          >
            {t("reference.brochure.nextBtn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Brochure;
