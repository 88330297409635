import { useTranslation } from "react-i18next";
import "design/productCss/software.css";
const Software = () => {
  const { t } = useTranslation();
  return (
    <div className={"software-container"}>
      <div className={"software-title"}>
        <h1>{t("menu.software")}</h1>
      </div>
      <div className={"software-content"}>
        <div className={"software-product-list"} id={"software-meterarium"}>
          <div className={"product-img"} />
          <div className={"product-description"}>
            <h1>{t("product.software.item1.platform")}</h1>
            <p>{t("product.software.item1.description1")}</p>
            <p>{t("product.software.item1.description2")}</p>
          </div>
        </div>
        <div className={"software-product-list"} id={"software-deepLearn"}>
          <div className={"product-img"} />
          <div className={"product-description"}>
            <h1> {t("product.software.item2.platform")}</h1>
            <p>{t("product.software.item2.description")}</p>
          </div>
        </div>
        <div className={"software-product-list"} id={"software-security"}>
          <div className={"product-img"} />
          <div className={"product-description"}>
            <h1> {t("product.software.item3.platform")}</h1>
            <p>{t("product.software.item3.description1")}</p>
            <p>{t("product.software.item3.description2")}</p>
          </div>
        </div>
        <div className={"software-product-list"} id={"software-wasap"}>
          <div className={"product-img"} />
          <div className={"product-description"}>
            <h1>{t("product.software.item4.platform")}</h1>
            <p>{t("product.software.item4.description")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Software;
