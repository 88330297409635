import { useTranslation } from "react-i18next";
import "design/communityCss/notice.css";
import SearchIcon from "assets/images/search.jpg";
const Notice = () => {
  const { t } = useTranslation();

  return (
    <div className={"notice-container"}>
      <div className={"notice-title"}>
        <h1>{t("menu.notice")}</h1>
      </div>
      <div className={"notice-content"}>
        <div className={"search-section"}>
          <select>
            <option value="">{t("community.notice.search.all")}</option>
            <option value="">{t("community.notice.search.title")}</option>
            <option value="">{t("community.notice.search.content")}</option>
          </select>
          <input
            type={"text"}
            placeholder={t("community.notice.search.placeholder")}
            className={"search-input"}
          />
          <button>
            <img src={SearchIcon} alt={"search"} width={15} height={15} />
          </button>
        </div>
        <div className={"table-section"}>
          <table>
            <thead>
              <tr>
                <th>{t("community.notice.table.no")}</th>
                <th>{t("community.notice.table.title")}</th>
                <th>{t("community.notice.table.view")}</th>
                <th>{t("community.notice.table.createAt")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4}>{t("community.notice.table.noData")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Notice;
