import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import i18n from "locales/i18n";
import HeaderBackground from "screens/reference/layout/headerBackground";
import Brochure from "screens/reference/components/brochure";
import Certification from "screens/reference/components/certification";
import ErrorScreen from "screens/error/errorScreen";

const ReferenceScreen = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  return (
    <Routes>
      <Route
        path="/brochure"
        element={
          <div>
            <HeaderBackground
              route={[t("header.reference"), t("menu.brochure")]}
            />
            <Brochure />
          </div>
        }
      />
      <Route
        path="/certification"
        element={
          <div>
            <HeaderBackground
              route={[t("header.reference"), t("menu.certification")]}
            />
            <Certification />
          </div>
        }
      />
      <Route path={"*"} element={<ErrorScreen />} />
    </Routes>
  );
};

export default ReferenceScreen;
