import { BrowserRouter, Route, Routes } from "react-router-dom";
import MasterLayout from "Layout/MasterLayout";
import MainScreen from "screens/main/mainScreen";
import IntroduceScreen from "screens/introduce/introduceScreen";
import BusinessScreen from "screens/business/businessScreen";
import ProductScreen from "screens/product/productScreen";
import ReferenceScreen from "screens/reference/referenceScreen";
import CommunityScreen from "screens/community/communityScreen";
import ErrorScreen from "screens/error/errorScreen";

const MainRouter = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<MainScreen />} />
        <Route path={"/about/*"} element={<IntroduceScreen />} />
        <Route path={"/business/*"} element={<BusinessScreen />} />
        <Route path={"/product/*"} element={<ProductScreen />} />
        <Route path={"/reference/*"} element={<ReferenceScreen />} />
        <Route path={"/community/*"} element={<CommunityScreen />} />
        <Route path={"*"} element={<ErrorScreen />} />
      </Routes>
    </BrowserRouter>
  );
};
export default MainRouter;
