import { useTranslation } from "react-i18next";
import "design/productCss/hardware.css";
const Hardware = () => {
  const { t } = useTranslation();
  return (
    <div className={"hardware-container"}>
      <div className={"hardware-title"}>
        <h1>{t("menu.hardWare")}</h1>
      </div>
      <div className={"hardware-content"}>
        <div className={"hardware-product-list"}>
          <div className={"product-img"} />
          <div className={"product-description"}>
            <h1>{t("product.hardware.item1.platform")}</h1>
            <p>{t("product.hardware.item1.description1")}</p>
          </div>
        </div>
        <div className={"hardware-product-list"}>
          <div className={"product-img"} />
          <div className={"product-description"}>
            <h1> {t("product.hardware.item2.platform")}</h1>
            <p>{t("product.hardware.item2.description1")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hardware;
