import "design/LayoutCss/Footer.css";
import LogoWhite from "assets/images/yit-white.png";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "locales/i18n";
import { useSelector } from "react-redux";
const Footer = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  return (
    <footer id={"yit-footer"} className={"footer-container"}>
      <div className={"footer-top"}>
        <img src={LogoWhite} alt={"yit-logo"} />
        <span>&copy; 2023-{new Date().getFullYear()} Young IT Co., Ltd.</span>
      </div>
      <div className={"footer-bottom"}>
        <nav className={"footer-nav"}>
          <ul className={"row"}>
            <li className={"col-md-3"}>
              <a href={"/about/youngit"} style={{ pointerEvents: "none" }}>
                {t("header.introduce")}
              </a>
              <ul className={"sub-ul"}>
                <li>
                  <a href={"/about/youngit"}>{t("menu.overview")}</a>
                </li>
                <li>
                  <a href={"/about/organization"}>{t("menu.organization")}</a>
                </li>
                <li>
                  <a href={"/about/way"}>{t("menu.way")}</a>
                </li>
              </ul>
            </li>
            <li className={"col-md-3"}>
              <a
                href={"/business/smart-check"}
                style={{ pointerEvents: "none" }}
              >
                {t("header.business")}
              </a>
              <ul className={"sub-ul"}>
                <li>
                  <a href={"/business/smart-check"}>{t("menu.smartCheck")}</a>
                </li>
              </ul>
            </li>
            <li className={"col-md-3"}>
              <a href={"/product/software"} style={{ pointerEvents: "none" }}>
                {t("header.product")}
              </a>
              <ul className={"sub-ul"}>
                <li>
                  <a href={"/product/software"}>{t("menu.software")}</a>
                </li>
                <li>
                  <a href={"/product/hardware"}>{t("menu.hardWare")}</a>
                </li>
              </ul>
            </li>
            <li className={" col-md-3"}>
              <a
                href={"/reference/brochure"}
                style={{
                  pointerEvents: "none",
                }}
              >
                {t("header.reference")}
              </a>
              <ul className={"sub-ul"}>
                <li>
                  <a href={"/reference/brochure"}>{t("menu.brochure")}</a>
                </li>
                <li>
                  <a href={"/reference/certification"}>
                    {t("menu.certification")}
                  </a>
                </li>
              </ul>
            </li>
            <li className={"col-md-3"}>
              <a
                href={"/community/notice"}
                style={{
                  pointerEvents: "none",
                }}
              >
                {t("header.community")}
              </a>
              <ul className={"sub-ul"}>
                <li>
                  <a href={"/community/notice"}>{t("menu.notice")}</a>
                </li>
                <li>
                  <a href={"/community/recruit"}>{t("menu.recruit")}</a>
                </li>
                <li>
                  <a href={"/community/guide"}>{t("menu.guide")}</a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
      <div className={"m-footer-bottom"}>
        <div className={"first-row"}>
          <span>{t("overview.address")}</span>
          <span>TEL: 042-932-0170</span>
          <span>FAX: 042-932-0170</span>
        </div>
        <div className={"first-row"}>
          <span>Email: young-it@young-it.co.kr</span>
          <a href={"TEL:070-7835-745"}>{t("overview.tech")}: 070-7835-7454</a>
        </div>
        <div className={"first-row"}>
          <span>
            Copyright &copy; 2023-{new Date().getFullYear()} Young IT CO., LTD.
            All rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
