import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import i18n from "locales/i18n";
import SmartCheck from "screens/business/componets/smartCheck";
import HeaderBackground from "screens/business/layout/headerBackground";
import ErrorScreen from "screens/error/errorScreen";

const BusinessScreen = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  return (
    <Routes>
      <Route
        path="/smart-check"
        element={
          <div>
            <HeaderBackground
              route={[t("header.business"), t("menu.smartCheck")]}
            />
            <SmartCheck />
          </div>
        }
      />
      <Route path={"*"} element={<ErrorScreen />} />
    </Routes>
  );
};

export default BusinessScreen;
