import "design/mainCss/lineBanner.css";
import { useTranslation } from "react-i18next";
const LineBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="line-banner">
      <div className="line-banner__content">
        <h1 className="line-banner__title">{t("main.banner.banner1des")}</h1>
        <p className="line-banner__description">
          {t("lineBanner.description1")}
        </p>
        <p className="line-banner__description">
          {t("lineBanner.description2")}
        </p>
      </div>
    </div>
  );
};
export default LineBanner;
