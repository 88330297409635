import { changeLanguage } from "../actionTypes";

const initialState = {
  language: "ko",
};

const pageSizeControlReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case changeLanguage.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    default:
      return state;
  }
};

export default pageSizeControlReducer;
