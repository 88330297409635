import { Route, Routes } from "react-router-dom";
import Overview from "screens/introduce/components/overview";
import HeaderBackground from "screens/introduce/layout/headerBackground";
import { useTranslation } from "react-i18next";
import Organization from "screens/introduce/components/organization";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import i18n from "locales/i18n";
import Way from "screens/introduce/components/way";
import ErrorScreen from "screens/error/errorScreen";

const IntroduceScreen = () => {
  const { t } = useTranslation();
  const language = useSelector((state: any) => state?.language?.language);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);
  return (
    <Routes>
      <Route
        path="/youngit"
        element={
          <div>
            <HeaderBackground
              route={[t("header.introduce"), t("menu.overview")]}
            />
            <Overview />
          </div>
        }
      />
      <Route
        path="/organization"
        element={
          <div>
            <HeaderBackground
              route={[t("header.introduce"), t("menu.organization")]}
            />
            <Organization />
          </div>
        }
      />
      <Route
        path="/way"
        element={
          <div>
            <HeaderBackground route={[t("header.introduce"), t("menu.way")]} />
            <Way />
          </div>
        }
      />
      <Route path={"*"} element={<ErrorScreen />} />
    </Routes>
  );
};

export default IntroduceScreen;
