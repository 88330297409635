import { useTranslation } from "react-i18next";
import "design/referenceCss/certification.css";
const Certification = () => {
  const { t } = useTranslation();
  return (
    <div className={"certification-container"}>
      <div className={"certification-title"}>
        <h1>{t("menu.certification")}</h1>
      </div>
      <div className={"certification-content"}>
        <div className={"__authentication_section"}>
          <h1>{t("reference.certification.authentication")}</h1>
          <div className={"__authentication_section_detail"}>
            <div className={"auth-card"}></div>
            <div className={"auth-card"}></div>
            <div className={"auth-card"}></div>
            <div className={"auth-card"}></div>
            <div className={"auth-card"}></div>
            <div className={"auth-card"}></div>
            <div className={"auth-card"}></div>
            <div className={"auth-card"}></div>
          </div>
        </div>
        <div className={"__certificate_section"}>
          <h1>{t("reference.certification.certificates")}</h1>
          <div className={"__certificate_section_detail"}>
            <div className={"certificate-card"}></div>
            <div className={"certificate-card"}></div>
            <div className={"certificate-card"}></div>
            <div className={"certificate-card"}></div>
            <div className={"certificate-card"}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Certification;
